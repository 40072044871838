import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import PageContainer from "../components/PageContainer"
import NavWithLogo from "../components/Layout/NavWithLogo"
import HeroWithLogo from "../components/HeroWithLogo"

const LegalNotice = () => (
  <Layout nav={<NavWithLogo />}>
    <Seo title="Legal Notice / Imprint" />
    <PageContainer>
      <HeroWithLogo />
      <h1>Address and Contact / Imprint</h1>
      <h2>Contact</h2>
      <dl>
        <dt>Name of the provider:</dt>
        <dd>
          <strong>Hanso Pte. Ltd.</strong>
        </dd>
      </dl>

      <dl>
        <dt>Company registration number (UEN):</dt>
        <dd>
          <strong>201937629R</strong>
        </dd>
      </dl>

      <dl>
        <dt>Authorised representative:</dt>
        <dd>
          <strong>Julian Lindner</strong>
        </dd>
      </dl>

      <dl>
        <dt>Postal address:</dt>
        <dd>
          <strong>
            30 Petain Road
            <br />
            Singapore 208099
          </strong>
        </dd>
      </dl>

      <dl>
        <dt>Contact details:</dt>
        <dd>
          E-mail:{" "}
          <strong>
            <a href="mailto:info@hanso.group">info@hanso.group</a>
          </strong>
          <br />
          Website:{" "}
          <strong>
            <a href="https://www.hanso.group">www.hanso.group</a>
          </strong>
        </dd>
      </dl>

      <h2>
        1. Person responsible according to § 18 Abs. 2 Medienstaatsvertrag
      </h2>
      <p>Julian Lindner</p>
      <h2>
        2. Information according to § 36 Verbraucherstreitbeilegungsgesetz
      </h2>
      <p>
        We are legally obliged to refer you to the European Online Dispute
        Resolution Platform (OS Platform) of the European Commission, which you
        can reach at{" "}
        <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>. However,{" "}
        <strong>Hanso Pte. Ltd.</strong> does not participate in a dispute
        resolution procedure before a consumer arbitration board, and we are not
        obliged to do so.
      </p>
      <h2>3. Liability for content</h2>
      <p>
        The contents of our pages were created with the greatest care. However,
        we cannot assume any liability for the correctness, completeness and
        up-to-dateness of the contents.
      </p>
      <h2>4. Liability for links</h2>
      <p>
        Our offer contains links to external websites of third parties, on whose
        contents we have no influence. Therefore, we cannot assume any liability
        for these external contents. The respective provider or operator of the
        pages is always responsible for the content of the linked pages. The
        linked pages were checked for possible legal violations at the time of
        linking. Illegal contents were not recognizable at the time of linking.
        However, permanent monitoring of the content of the linked pages is not
        reasonable without concrete indications of a legal violation. If we
        become aware of any infringements of the law, we will remove such links
        immediately.
      </p>
      <h2>5. Copyright</h2>
      <p>
        Contents of the website (photos, texts, images, videos, graphics etc.)
        are protected by copyright.
      </p>
      <h2>6. Data protection</h2>
      <p>See the separate privacy policy.</p>
      <h2>7. Objection to advertising emails</h2>
      <p>
        It is expressly forbidden to use the contact data provided here in the
        imprint and on the website to send advertising that has not been
        expressly requested directly in writing. Should this express objection
        nevertheless be disregarded, the website operators reserve the right to
        take legal action against this!
      </p>
    </PageContainer>
  </Layout>
)

export default LegalNotice
